export default {
  TOTAL_HITS: 'نتائج',
  LOAD_MORE_TEXT: '<span>تحميل المزيد</span>',
  SORT_BY: 'صنف حسب',

  START_OVER: 'ابدأ من جديد',
  CURRENT_SEARCH: 'البحث الحالي',
  PRICE_FACET_GO_TEXT: 'يذهب',
  MIN_PRICE: 'سعر دقيقة',
  MAX_PRICE: 'صى سعر',
  FACET_FILTER_PLACEHOLDER: 'أدخل',
  FACET_FILTER_NOTHING: 'لم يتم العثور على إدخالات',
  SHOW_MORE: 'أظهر المزيد',
  SHOW_LESS: 'عرض أقل',

  FILTERS_TEXT: 'المرشحات',
  FACET_DIALOG_TITLE: 'المرشحات',
  FACET_DIALOG_RESULTS: 'نتائج',
  FACET_DIALOG_CLEAR: 'صافي',
  FACET_DIALOG_DONE: 'فعله',

  SEARCH_BOX_PLACEHOLDER: 'بحث في جميع المنتجات',
  SEARCH_BOX_CLEAR_BUTTON: 'صافي',
  SEARCH_BOX_SECTION_TITLE: `{this.totalHits > 1 ? \`\${this.totalHits} \` : ''}
    {this.sectionTitle === 'Products' ? 'منتجات' : this.sectionTitle}
    {!!this.vehicleString ? \` ل \${this.vehicleString}\` : ''}`,
  BROWSE_ALL: 'تصفح جميع المنتجات',

  SELECT_YOUR_VEHICLE_BLOCK: '<h3>حدد سيارتك</h3>',
  SELECT_YOUR_VEHICLE: 'حدد سيارتك',
  CHANGE_VEHICLE: 'تغيير السيارة',
  CHANGE_TEXT: 'تغيير السيارة',
  DISCARD_VEHICLE: 'مسح',
  GO_TEXT: 'ابحث',
  CLEAR_TEXT: 'مسح',

  GARAGE_TITLE: 'مرآبي',
  CLEAR_GARAGE_TEXT: 'جراج نظيف',
  ADD_VEHICLE: 'أضف مركبة',
  STORE_VEHICLE_IN_GARAGE: 'قم بتخزين سيارتك في المرآب',
  GET_PRODUCTS_FOR_VEHICLE: 'احصل على منتجات لسيارتك',
  FIND_PARTS_YOU_NEED: 'اعثر بسهولة على الأجزاء التي تحتاجها',

  VERIFY_FITMENT_TITLE: 'تحقق من امكانية التركيب على مركبتك',
  VERIFY_FITMENT_FITS: 'هذا المنتج يناسب سيارت',
  VERIFY_FITMENT_NOT_FIT: 'هذا المنتج لا يناسب سيارتك',
  VERIFY_FITMENT_SPECIFY: `رجاء حدد {{fieldsToSpecify.join(", ")}} من أجلك {{vehicleString}}`,
  FITMENT_UNIVERSAL_TITLE: 'تناسب عام',
  FITMENT_UNIVERSAL_TEXT: 'قد يتطلب هذا المنتج التعديل.',
  FITMENT_UNKNOWN_TITLE: 'لا توجد بيانات الإعداد',
  FITMENT_UNKNOWN_TEXT: 'لا يحتوي هذا المنتج على بيانات تطبيق الإعداد. يرجى التحقق من الإعداد يدويًا.',

  FITMENT_TABLE_VEHICLE_SPECIFIC: 'الإعداد',

  FACET_BAR_WHEELS_TIRES_TITLE: `{facet_bar_label.toLowerCase().includes("wheels") ? 'تسوق العجلات' : 'متجر الإطارات'}`,

  SELECT_TITLE: `{window.Convermax.fitmentFieldsTranslation[this.title.replaceAll(' ', '_')] || this.title}`,
};
