import shopifyWarehouseDefault from '../shopify-warehouse/config.js';

window.Convermax.fitmentFieldsTranslation = {
  Year: 'سنة',
  Make: 'الشركة الصانعة',
  Model: 'الموديل',
  Submodel: 'الفئة',
  // eslint-disable-next-line camelcase
  Bolt_Pattern: 'نمط جريء',
  Diameter: 'قطر الدائرة',
  Offset: 'عوض',
  Width: 'عرض',
  Aspect: 'جانب',
  Rim: 'حافة',
};

export default {
  includes: ['shopify-warehouse'],
  ...shopifyWarehouseDefault,
  Widgets: [
    ...shopifyWarehouseDefault.Widgets.filter((w) => !['HomeVehicleWidget', 'SearchHeader'].includes(w.name)),
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'افضل نتيجة',
        'title': 'بترتيب الابجدية',
        'title:desc': 'عكس ترتيب الابجدية',
        'collection_sort': 'المميز',
        'price': 'من الاقل سعراً الى الاعلى',
        'price:desc': 'من الاعلى سعراً الى الاقل',
        'published_at': 'من الاقدم الى الاحدث',
        'published_at:desc': 'من الاحدث الى الاقدم',
      },
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '.cm_tab-content.cm_vehicle-search',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 700,
    },
    {
      name: 'SearchBox_tab',
      type: 'SearchBox',
      location: { selector: '.cm_tab-content.cm_vin-search', class: 'cm_search-box-root__tab' },
      template: 'SearchBox',
      disableDropdown: true,
    },
  ],
};
