const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/collections';
const brandSelectionPageUrl = '/pages/shop-by-brand';

const vehicleStore = globalThis.Convermax?.fitmentSearch?.vehicleStore;

// because Vehicle field on landings is sending from the Client, we need to hide it manually from chips
if (vehicleStore) {
  ignoreFields.push('Vehicle');
}

function getLocalPreselection(pageType, defaults) {
  if (pageType === 'category') {
    return [{ field: 'collection_ids', term: window.Convermax.collectionId }];
  }
  return defaults.getter(pageType);
}

export default {
  platform: 'shopify',
  searchPageUrl: '/search',
  defaultView: window.Convermax.config?.defaultView ?? 'vertical',
  SearchRequestDefaults: {
    selection: vehicleStore ? [{ field: 'Vehicle', term: vehicleStore }] : undefined,
    pageSize: window.Convermax.config?.pageSize || 36,
    sort: window.Convermax.config?.customSort,
  },
  page: {
    getPageType: (defaults) =>
      vehicleStore
        ? 'parts'
        : window.location.href.indexOf('vendors') > -1
          ? 'brand'
          : window.document.querySelector('#cm_category-search-page')
            ? 'category'
            : window.document.querySelector('body.template-index')
              ? 'home'
              : defaults.getter(),
  },
  getLocalPreselection,
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: window.Convermax.config?.categorySelectionPages || [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
      { pathname: vehicleStore ? window.location.pathname : null, field: 'category' },
    ],
    categorySelectionPageRedirect: window.Convermax.config?.categorySelectionPageRedirect ?? true,
    expectResponseRedirect: true,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm_category-search-page',
      template: 'SearchPage',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_category-selection-page',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: '.header__search-bar-wrapper',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.header__action-item-link[data-action="toggle-search"]',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'Garage',
      location: {
        insertBefore: '.header__action-item--cart',
        class: 'header__action-item',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: {
        firstChildOf: 'body.template-index main#main',
        class: 'cm_vehicle-widget__header-container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      columnBreakpoint: 700,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        firstChildOf: 'body:not(.template-index) main#main',
        class: 'cm_vehicle-widget__header-container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      columnBreakpoint: 700,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: {
        insertBefore: 'body.template-product .product-form__info-list',
      },
      template: 'fitmentSearch/verifyFitment',
      columnBreakpoint: 500,
    },
    {
      name: 'FitmentTable',
      location: {
        insertAfter: '.product-block-list__item--description',
        class: 'product-block-list__item',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'SearchVehicleMessage',
      type: 'VehicleWidget',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
      initExpandedFacets: ['category'],
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
